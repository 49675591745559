/**
 * This componet handles form navigations. Wrapper over all the steps.
 */

import React, { FormEvent, ReactElement, useEffect, useRef, useState } from 'react';

import useSubmitApi from '../../../apis/useSubmitApi';
import useClocStore from '../../../store';
import { IClocFormData, IClocState } from '../../../store/types';
import { isWindow } from '../../../utils/jsUtils';
import Btn from '../../atoms/button';
import CompLoader from '../../atoms/compLoader';
import BtnGroup from '../btnGroup';
import StepTracker from '../stepTracker';
import styles from './formWrapper.module.scss';

export interface IFormListElem {
  key: string;
  comp?: ReactElement;
  aemPath: string;
}

export interface IUseFormHook {
  defaultStep: number;
  steps: IFormListElem[];
}

export interface IFormWrapper {
  forms: IFormListElem[];
  isAuthorView: boolean;
  aemPath: string;
}

const getCompletedSteps = (formSteps: IFormListElem[], stepsStatus: Record<string, boolean>) => {
  const completed: number[] = [];
  formSteps.forEach(({ key }, i) => {
    const k: keyof IClocFormData = key as any;
    const isValid = Boolean(stepsStatus[k]);
    if (isValid) {
      completed.push(i + 1);
    }
  });
  return completed;
};

const useForm = ({ steps, defaultStep }: IUseFormHook) => {
  const [currentStep, setCurrentStep] = useState<number>(defaultStep);
  const totalSteps = steps.length;

  const gotoNext = () => {
    if (currentStep === totalSteps) {
      return;
    }

    document.getElementById('cloc-form')?.focus();
    return setCurrentStep((currentStep) => currentStep + 1);
  };

  const gotoBack = () => {
    if (currentStep === 1) {
      return;
    }

    document.getElementById('cloc-form')?.focus();
    return setCurrentStep((currentStep) => currentStep - 1);
  };

  const gotoStep = (n: number) => {
    setCurrentStep(n);
  };

  return { gotoNext, gotoBack, gotoStep, currentStep, totalSteps, steps };
};

const FormWrapper: React.FC<IFormWrapper> = ({ forms, aemPath, isAuthorView }) => {
  const { totalSteps, gotoNext, gotoBack, currentStep, steps, gotoStep } = useForm({
    steps: forms,
    defaultStep: 1
  });
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
  const { fireAPI, apiState: submitApiState } = useSubmitApi();
  const updateRootPage = useClocStore((state: { updateRootPage: any }) => state.updateRootPage);
  const updateFormData = useClocStore((state: any) => state.updateFormData);
  const { valid: isClocFormValid, stepsStatus } = useClocStore((state: any) => state.isClocFormValid);
  const isSubmitValid = useClocStore((state) => (state as IClocState).clocFormData.signAndSubmit?.isValid);
  const isLoading = useClocStore((state) => state.isLoading);

  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  const formRef = useRef<HTMLFormElement>(null);

  const jumpToStep = (n: number) => {
    // set isVisited true for all forms below n
    for (let i = 0; i < n - 2; ++i) {
      updateFormData(steps[i].key, (obj: any) => {
        obj.isVisited = true;
      });
    }
    gotoStep(n);
    if (isWindow()) {
      window.scrollTo(0, 0);
    }
  };

  const stepClickHandler = (num: number) => {
    jumpToStep(num);
  };

  const doNav = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    const target = e.currentTarget;
    let action: string | null = null;

    if (target.dataset.action) {
      action = target.dataset.action;
    } else if (target instanceof HTMLButtonElement && target.name) {
      action = target.name;
    }

    if (action === 'next') {
      gotoNext();
    } else if (action === 'prev') {
      gotoBack();
    }

    if (isWindow()) {
      window.scrollTo(0, 0);
    }
  };

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
  };

  const initSubmit = () => {
    console.log('🚀 Triggered initSubmit API in FormController');
    if (!isLoading) {
      fireAPI();
    }
  };

  useEffect(() => {
    if (submitApiState?.data) {
      updateRootPage('survey');
    }
  }, [submitApiState]);

  const navBtns = (): React.JSX.Element[] => {
    const btns: React.JSX.Element[] = [];

    if (currentStep > 1 || isAuthorView) {
      btns.push(
        <Btn
          key="prev"
          name="prev"
          onClick={doNav}
          // directProps={{ icon: 'caretLeft', iconPosition: 'left', size: 'small' }}
        />
      );
    }
    // ... rest of the navBtns function remains largely unchanged ...

    if (currentStep < totalSteps) {
      let buttonTestId;
      const stepKey = steps[currentStep - 1].key;

      if (stepKey === 'applicationReview' && !isClocFormValid) {
        buttonTestId = 'applicationReviewNextButton';
      } else if (stepKey === 'contactInformation') {
        buttonTestId = 'businessContactInformationNextButton';
      } else if (stepKey === 'cccdAgreement') {
        buttonTestId = 'crossCollateralCrossDefaultNextButton';
      } else {
        buttonTestId = `${stepKey}NextButton`;
      }

      btns.push(
        <Btn
          data-testid={buttonTestId}
          key="next"
          name="next"
          isDisabled={stepKey === 'applicationReview' && !isClocFormValid}
          onClick={doNav}
        />
      );
    }

    if (currentStep === totalSteps || isAuthorView) {
      btns.push(
        <Btn
          id="finalSubmissionButton"
          key="submit-application"
          name="submit-application"
          onClick={() => {
            setIsSubmitButtonDisabled(true);
            initSubmit();
          }}
          disabled={isSubmitButtonDisabled || !isSubmitValid || isLoading}
        />
      );
    }

    return btns;
  };

  useEffect(() => {
    useClocStore.setState({ updateReviewStep: ((n: number) => jumpToStep(n)) as any });
  }, []);

  /**
   * Document are not restored on page refresh, need to rerender progress tracker to reflect correct status
   */
  useEffect(() => {
    setCompletedSteps(getCompletedSteps(forms, stepsStatus));
  }, [currentStep, stepsStatus]);

  const disabledSteps = isClocFormValid ? [] : [steps.length];

  return (
    <div className={styles.formContainer}>
      <StepTracker
        name="cloc-tracker"
        activeStep={currentStep}
        completedSteps={completedSteps}
        disabledSteps={disabledSteps}
        stepClickHandler={stepClickHandler}
        aemPath={aemPath}
      />
      <form
        id="cloc-form"
        ref={formRef}
        onSubmit={submitHandler}
        tabIndex={-1}
        noValidate>
        {isAuthorView ? <p>{`Go to step url to edit form steps`}</p> : <CompLoader path={steps[currentStep - 1].key} />}
      </form>
      <BtnGroup
        className={styles.nav}
        btns={navBtns()}
      />
    </div>
  );
};

export default FormWrapper;
