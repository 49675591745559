import { EditableAccordion, IAccordionItemProps } from '@fcamna/aem-library';
import { Accordion as AccordionCmp, AccordionItem, AccordionPanel } from '@fcamna/react-library';
import { useState } from 'react';
interface AccordionProps {
  name: string;
  accordionClassName?: string;
  accordionContent?: React.ReactElement | string;
}

export default function Accordion({ name, accordionClassName = '', accordionContent = '' }: Readonly<AccordionProps>) {
  const isTestEnvironment = process.env.NODE_ENV === 'test';
  const [isOpen, setIsOpen] = useState(false);

  if (isTestEnvironment) {
    return <div data-testid={name}>{accordionContent}</div>;
  }

  return (
    <EditableAccordion
      name={name}
      renderAccordion={(props: Partial<IAccordionItemProps>, index: number) => {
        const items: Partial<IAccordionItemProps>[] = (props as any).items;
        const boxVariation: boolean | undefined = (props as any).boxVariation;

        return (
          <AccordionCmp className={accordionClassName} boxVariation={boxVariation}>
            {items?.map((item) => {
              const { title, subTitle } = item as IAccordionItemProps; // Define the type here

              return (
                <AccordionItem
                  key={title}
                  id={title}
                  title={title}
                  subTitle={subTitle} // subTitle should now have the correct type
                  boxVariation={boxVariation} // Assuming boxVariation is a string
                  openAccordion={isOpen}
                  setOpenAccordion={(_event, _id, value) => setIsOpen(value)}>
                  <AccordionPanel children={accordionContent} />
                </AccordionItem>
              );
            })}
          </AccordionCmp>
        );
      }}
    />
  );
}
