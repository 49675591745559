import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { MutableRefObject, useEffect, useRef } from 'react';

import useClocStore from '../../../store';
import { IClocState, IDynamicObj } from '../../../store/types';
import { getAEMPath, isValidEmail, isValidUSPhone } from '../../../utils/jsUtils';
import useValidationStatus from '../../../utils/useValidationStatus';
import ButtonWrapper from '../../atoms/button';
import fordProIcons from '../../atoms/icon';
import Input from '../../atoms/input';
import RadioButtonGroup from '../../atoms/radio/radio';
import Text from '../../atoms/text';
import { fireAnalyticsLoad } from '../../molecules/Analytics';
import BusinessOwners from './BusinessOwners';
import styles from './contactInfo.module.scss';
import PersonalGurantors from './PersonalGuarantors';
import { validateSchema } from './validation';

const AEM_PATH = getAEMPath('contactInformation');
const { Add: AddSign, CancelClose: CloseRemove } = fordProIcons;

const customValidations = {
  email: (val: string) => {
    if (!isValidEmail(val)) {
      return 'invalid';
    }

    return '';
  },
  phone: (val: string) => {
    if (!isValidUSPhone(val)) {
      return 'invalid';
    }

    return '';
  }
};

export default function ContactInformation(): React.JSX.Element | null {
  const isAuthorView = AuthoringUtils.isInEditor();
  const RADIO_OPTIONS = ['Yes', 'No'];
  useValidationStatus({ key: 'contactInformation', validationFn: validateSchema });

  const updateFormData = useClocStore((state) => (state as IClocState).updateFormData);

  const isValid = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.isValid);
  const isSecondaryContact = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.isSecondaryContact);

  const region = useClocStore((state) => (state as IClocState).region);
  // This is one time read on init, no rerender
  const defaultValues: MutableRefObject<IDynamicObj> = useRef((useClocStore.getState() as IClocState).clocFormData.contactInformation);
  const {
    isVisited,
    firstName,
    lastName,
    title,
    phoneNum,
    email,
    secondaryFirstName,
    secondaryLastName,
    secondaryTitle,
    secondaryPhoneNum,
    secondaryEmail
  } = defaultValues.current;
  const isPGProvided = useClocStore((state) => (state as IClocState).clocFormData.contactInformation.isPGProvided);

  const formInComplete = !!(isVisited && !isValid);

  useEffect(() => {
    if (!isVisited) {
      updateFormData('contactInformation', (obj: IDynamicObj) => {
        obj.isVisited = true;
      });
    }

    fireAnalyticsLoad({ pageName: 'contactInfo' });
  }, []);

  const changeHandler = ({ name, value }: Record<string, string | number | boolean>) => {
    updateFormData('contactInformation', (obj: IDynamicObj) => {
      obj[name as string] = value;
      if (name === 'isPGProvided') {
        const pgList = value === 'Yes' ? [{ isPrimaryContact: false }] : undefined;
        obj.personalGuarantors = pgList as IDynamicObj[];
      }

      /* 
      Added below updated to bo and pg if "Same as primary contact is selected" and if anything in primary conact is updated
      It should be updated in BO and PG as well, and only 1st PG and BO can be same as primaryContact hence only index[0] is modified
      */
      if (
        Array.isArray(obj.businessOwners) &&
        obj.businessOwners.length &&
        (obj.businessOwners[0] as Record<string, string | boolean>).isPrimaryContact &&
        ['firstName', 'lastName', 'title'].includes(name as string)
      ) {
        (obj.businessOwners[0] as IDynamicObj).boFirstName = obj.firstName;
        (obj.businessOwners[0] as IDynamicObj).boLastName = obj.lastName;
        (obj.businessOwners[0] as IDynamicObj).boTitle = obj.title;
      }

      if (
        Array.isArray(obj.personalGuarantors) &&
        obj.personalGuarantors.length &&
        (obj.personalGuarantors[0] as Record<string, string | boolean>).isPrimaryContact &&
        ['firstName', 'lastName', 'phoneNum', 'email'].includes(name as string)
      ) {
        (obj.personalGuarantors[0] as IDynamicObj).pgFirstName = obj.firstName;
        (obj.personalGuarantors[0] as IDynamicObj).pgLastName = obj.lastName;
        (obj.personalGuarantors[0] as IDynamicObj).pgPhoneNum = obj.phoneNum;
        (obj.personalGuarantors[0] as IDynamicObj).pgEmail = obj.email;
      }
    });
  };
  const removeCallback = () => {
    changeHandler({ name: 'isSecondaryContact', value: false });
    changeHandler({ name: 'secondaryFirstName', value: '' });
    changeHandler({ name: 'secondaryLastName', value: '' });
    changeHandler({ name: 'secondaryTitle', value: '' });
    changeHandler({ name: 'secondaryPhoneNum', value: '' });
    changeHandler({ name: 'secondaryEmail', value: '' });
  };

  return (
    <>
      <div className={styles.section}>
        <Text name="form-heading" />
      </div>
      <div className={styles.section}>
        <Text name="primary-contact-heading" />
        <div className={styles.form}>
          <Input
            type="text"
            name="firstName"
            dataTestId="firstName"
            required
            defaultValue={firstName as string}
            changeHandler={changeHandler}
            validate={formInComplete}
            showBlankSpaceValidation
          />
          <Input
            type="text"
            name="lastName"
            dataTestId="lastName"
            required
            defaultValue={lastName as string}
            changeHandler={changeHandler}
            validate={formInComplete}
            showBlankSpaceValidation
          />
          <Input
            type="text"
            name="title"
            dataTestId="title"
            required
            defaultValue={title as string}
            changeHandler={changeHandler}
            validate={formInComplete}
            showBlankSpaceValidation
          />
          <Input
            type="tel"
            name="phoneNum"
            dataTestId="phone"
            required
            defaultValue={phoneNum as string}
            changeHandler={changeHandler}
            validate={formInComplete}
            customValidation={customValidations.phone}
            customErrorKeys={['invalid']}
            inputTypeFormat="tel"
          />
          <Input
            type="email"
            name="email"
            required
            defaultValue={email as string}
            changeHandler={changeHandler}
            validate={formInComplete}
            customValidation={customValidations.email}
            customErrorKeys={['invalid']}
            dataTestId="email"
          />
        </div>
      </div>
      <div className={styles.secondaryContactSection}>
        {region === 'us' && (isSecondaryContact || isAuthorView) ? (
          <div className={styles.compWrapper}>
            <div className={styles.form}>
              <Text name="secondary-contact-heading" />
              <Input
                type="text"
                name="secondaryFirstName"
                dataTestId="secondaryFirstName"
                defaultValue={secondaryFirstName as string}
                changeHandler={changeHandler}
                validate={formInComplete}
              />
              <Input
                type="text"
                name="secondaryLastName"
                dataTestId="secondaryLastName"
                defaultValue={secondaryLastName as string}
                changeHandler={changeHandler}
                validate={formInComplete}
              />
              <Input
                type="text"
                name="secondaryTitle"
                dataTestId="secondaryTitle"
                defaultValue={secondaryTitle as string}
                changeHandler={changeHandler}
                validate={formInComplete}
              />
              <Input
                type="tel"
                name="secondaryPhoneNum"
                dataTestId="secondaryPhoneNum"
                defaultValue={secondaryPhoneNum as string}
                changeHandler={changeHandler}
                validate={formInComplete}
                customValidation={customValidations.phone}
                customErrorKeys={['invalid']}
                inputTypeFormat="tel"
              />
              <Input
                type="email"
                name="secondaryEmail"
                defaultValue={secondaryEmail as string}
                changeHandler={changeHandler}
                validate={formInComplete}
                customValidation={customValidations.email}
                customErrorKeys={['invalid']}
                dataTestId="secondaryEmail"
              />
            </div>
            <div className={styles.removeWrapper}>
              <ButtonWrapper
                type="button"
                name={`remove-secondaryContact`}
                onClick={removeCallback}
                directProps={{ icon: 'cancelClose', iconPosition: 'left', size: 'small' }}
              />
            </div>
          </div>
        ) : null}
      </div>
      <br />
      {region === 'us' && (!isSecondaryContact || isAuthorView) ? (
        <ButtonWrapper
          style={{ marginTop: '20px' }}
          className={styles.btn}
          type="button"
          name={`add-more-secondaryContact`}
          onClick={() => changeHandler({ name: 'isSecondaryContact', value: true })}
          directProps={{ icon: 'add', iconPosition: 'left', size: 'small' }}
        />
      ) : null}
      {/* Business Owners Section*/}
      <div className="cf-field-title">
        <Text name="business-owner-heading" />
        <div className="cf-field">
          <Text
            className={styles.percentageHelper}
            name="bo-total"
            bodySize="m"
            bodyWeight="m"
          />
        </div>
        <BusinessOwners
          validate={formInComplete}
          aemPath={AEM_PATH}
        />
      </div>

      {/* Personal Guarantors Section */}
      {region === 'us' ? (
        <div className={styles.section}>
          <Text name="personal-guarantor-heading" />
          <Text
            name="personal-guarantor-line1"
            className={styles.pgLine1}
          />
          <RadioButtonGroup
            name="isPGProvided"
            validate={formInComplete}
            changeHandler={changeHandler}
            optionsValue={RADIO_OPTIONS}
            required
            defaultValue={isPGProvided as string}
            aemPath={AEM_PATH}
          />
          <PersonalGurantors
            validate={formInComplete}
            aemPath={AEM_PATH}
          />
        </div>
      ) : null}
    </>
  );
}
